import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface Props {
  loggedIn: boolean
  notify: any
}
const ProtectedRoute = ({ loggedIn, notify }: Props) => {
  const token = sessionStorage.getItem('userId')

  if (loggedIn) {
    return <Outlet />
  } else if (token) {
    return <Outlet />
  } else {
    return (
      <>
        {notify('warn', 'Access denied! Please login')}
        <Navigate to='/' />
      </>
    )
  }
}
export default ProtectedRoute
