import React, { useState } from 'react'
import {
  FaSearch,
  FaDownload,
  FaCaretDown,
  FaCaretUp,
  FaTimes,
} from 'react-icons/fa'

const List = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

function CardTopup({ CardTopup1, CardTopup0, cardLoading, cardLoading1 }: any) {
  const [failList0, setFailList0] = useState<any>([
    {
      event: 'card topup',
      fulfilled_date: null,
      total_bill_ngn: 12331,
      user_id_payer: 940,
      sudo_charge: 12193.25,
      created: '2023-04-04 16:32:05.317949+01:00',
      paid_date: '2023-04-04 16:32:12.177534+01:00',
      card_id: '63ee15dcdfb2290214cdba27',
      email: 'info@groovefinance.io',
      holder_id: '63da793069fa6cf92f7034a9',
      paid: false,
      alias: 'Movies',
      user_id: '940',
      bill_request_id: '202304041632053178638W5zWZ20LRKEYFEg',
      user_wallet_id: '639e1e9d39591a2ab80fc5fc',
      amount: 15,
      sub_id: 162,
      main_wallet_ac_number: '8010475940',
      username: 'groove',
      fulfilled: true,
      narration:
        "Purchase of USD15.0 for virtual card 'Movies', at NGN760.0/USD: NGN11,400. Card funding charge: NGN931. Tranfer fee: 0. Total bill: NGN12,331 .",
      wallet_debited_successfully: true,
      fail_reason: 'issuer failure.',
      settlement_account_funded: true,
      request_enqueued: true,
      card_income_account_funded: true,
      total_attemps: 1,
      bridge_ac_funded: true,
    },
    {
      event: 'card topup',
      fulfilled_date: null,
      user_id_payer: 940,
      total_bill_ngn: 47044,
      sudo_charge: 46508,
      created: '2023-04-12 13:07:35.995824+01:00',
      paid_date: null,
      card_id: '6421adaba763bcba7a9325d6',
      email: 'info@groovefinance.io',
      holder_id: '6421ad7ba763bcba7a9324f9',
      paid: false,
      alias: 'Nnamdi Ifedozie SPC',
      user_id: '940',
      bill_request_id: '20230412130735995746sIwluoX0eCByxEXP',
      user_wallet_id: '639e1e9d39591a2ab80fc5fc',
      amount: 60,
      sub_id: 484,
      main_wallet_ac_number: '8010475940',
      username: 'groove',
      fulfilled: false,
      narration:
        "Purchase of USD60.0 for virtual card 'Nnamdi Ifedozie SPC', at NGN760.0/USD: NGN45,600. Card funding charge: NGN1,444. Tranfer fee: 0. Total bill: NGN47,044 .",
      wallet_debited_successfully: true,
      fail_reason: 'issuer failure.',
      settlement_account_funded: true,
      request_enqueued: true,
      card_income_account_funded: true,
      total_attemps: 1,
      bridge_ac_funded: true,
    },
  ])
  const [selectedNo, setSelectedNo] = useState<any>({})
  const [tab, setTab] = useState('level0')

  return (
    <div className='card-request'>
      <div className='top'>
        <div className='left'>
          <div className='search'>
            <div className='input'>
              <input type='text' placeholder='Search Username/Request ID' />
            </div>
            <div className='button'>
              <FaSearch />
            </div>
          </div>
        </div>
      </div>
      <div className='switch_button'>
        <button
          title='Failures due to error charging a user card or splitting charge into appropriate accounts.'
          className={tab === 'level0' ? 'ac' : ''}
          onClick={() => setTab('level0')}
        >
          {' '}
          Level 0
        </button>
        <button
          title='Failures due to error creating a card or toping it up after a successful charging of the user/business walle. .'
          className={tab === 'level1' ? 'ac' : ''}
          onClick={() => setTab('level1')}
        >
          {' '}
          Level 1
        </button>
      </div>
      {cardLoading ? (
        <div>Loading</div>
      ) : (
        <>
          {tab === 'level0' && (
            <div className='table'>
              <div className='head'>
                <div className='name'>
                  <p>Username</p>
                </div>
                <div className='date'>
                  <p>Request Date </p>
                </div>
                <div className='bill'>
                  <p>Total Bill (NGN) </p>
                </div>
                <div className='usd'>
                  <p>USD Amount </p>
                </div>
                <div className='number'>
                  <p>Wallet Account Number</p>
                </div>
              </div>
              <div className='body'>
                {CardTopup0.map((item: any) => (
                  <div className='row-cover'>
                    <div
                      className={
                        selectedNo.bill_request_id === item.bill_request_id
                          ? 'row active'
                          : 'row'
                      }
                      onClick={() => {
                        if (
                          selectedNo.bill_request_id === item.bill_request_id
                        ) {
                          setSelectedNo(0)
                        } else {
                          setSelectedNo(item)
                        }
                      }}
                    >
                      <div className='name'>
                        <p>{item.username} </p>
                      </div>
                      <div className='date'>
                        <p>{item.created.toLocaleString()} </p>
                      </div>
                      <div className='bill'>
                        <p>{item.total_bill_ngn.toLocaleString()} </p>
                      </div>
                      <div className='usd'>
                        <p>{item.amount.toLocaleString()}</p>
                      </div>
                      <div className='number'>
                        <p>{item.main_wallet_ac_number}</p>
                      </div>
                    </div>
                    <div
                      className={
                        selectedNo === item ? 'row-body active' : 'row-body'
                      }
                    >
                      <div className='wrap'>
                        <div className='card'>
                          <h2>User Id</h2>
                          <div className='input'>
                            <p>{item.user_id}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Alias</h2>
                          <div className='input'>
                            <p>{item.alias}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Wallet Debited Successfully</h2>
                          <div className='input'>
                            <p>{`${item.wallet_debited_successfully}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Income Account Funded Successfully</h2>
                          <div className='input'>
                            <p>{`${item.card_income_account_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Sudo Amount</h2>
                          <div className='input'>
                            <p># {item.sudo_charge}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Total Attempts</h2>
                          <div className='input'>
                            <p>{item.total_attemps}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Failure Reason</h2>
                          <div className='input'>
                            <p>{item.fail_reason}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Bridge Account Funded Successfully</h2>
                          <div className='input'>
                            <p>{`${item.bridge_ac_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Settlement Account Funded Successfully </h2>
                          <div className='input'>
                            <p>{`${item.card_income_account_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Request ID </h2>
                          <div className='input'>
                            <p>{item.bill_request_id}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Request Enqueue </h2>
                          <div className='input'>
                            <p>{`${item.request_enqueued}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Narration</h2>
                          <div className='input'>
                            <p>{item.narration}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {tab === 'level1' && (
            <div className='table'>
              <div className='head'>
                <div className='name'>
                  <p>Username</p>
                </div>
                <div className='date'>
                  <p>Request Date </p>
                </div>
                <div className='bill'>
                  <p>Total Bill (NGN) </p>
                </div>
                <div className='usd'>
                  <p>USD Amount </p>
                </div>
                <div className='number'>
                  <p>Wallet Account Number</p>
                </div>
              </div>
              <div className='body'>
                {!cardLoading1 ? (
                  <>
                    {CardTopup1.map((item: any) => (
                      <div className='row-cover'>
                        <div
                          className={
                            selectedNo.topup_id === item.topup_id
                              ? 'row active'
                              : 'row'
                          }
                          onClick={() => {
                            if (selectedNo.topup_id === item.topup_id) {
                              setSelectedNo(0)
                            } else {
                              setSelectedNo(item)
                            }
                          }}
                        >
                          <div className='name'>
                            <p>{item.username} </p>
                          </div>
                          <div className='date'>
                            <p>{item.create_date.toLocaleString()} </p>
                          </div>
                          <div className='bill'>
                            <p>{item.total_bill.toLocaleString()} </p>
                          </div>
                          <div className='usd'>
                            <p>{item.amount.toLocaleString()}</p>
                          </div>
                          <div className='number'>
                            <p>{item.main_wallet_ac_number}</p>
                          </div>
                        </div>
                        <div
                          className={
                            selectedNo === item ? 'row-body active' : 'row-body'
                          }
                        >
                          <div className='wrap'>
                            <div className='card'>
                              <h2>User Id</h2>
                              <div className='input'>
                                <p>{item.user_id}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Card Alias</h2>
                              <div className='input'>
                                <p>{item.alias}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Wallet Debited Successfully</h2>
                              <div className='input'>
                                <p>{`${item.wallet_debited_successfully}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Card Income Account Funded Successfully</h2>
                              <div className='input'>
                                <p>{`${item.card_income_account_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Sudo Amount</h2>
                              <div className='input'>
                                <p># {item.sudo_charge}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Total Attempts</h2>
                              <div className='input'>
                                <p>{item.total_attemps}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Failure Reason</h2>
                              <div className='input'>
                                <p>{item.fail_reason}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Bridge Account Funded Successfully</h2>
                              <div className='input'>
                                <p>{`${item.bridge_ac_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>
                                Card Settlement Account Funded Successfully{' '}
                              </h2>
                              <div className='input'>
                                <p>{`${item.card_income_account_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Request ID </h2>
                              <div className='input'>
                                <p>{item.bill_request_id}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Request Enqueue </h2>
                              <div className='input'>
                                <p>{`${item.request_enqueued}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Narration</h2>
                              <div className='input'>
                                <p>{item.narration}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CardTopup
