import React, { useEffect, useState } from 'react'
import Spinner from '../../../../Images/spinner.gif'
import Loader from '../../../../Images/loder.gif.gif'

function ForeignTrans({ notify, logOut }: any) {
  const [loading, setLoading] = useState(true)
  const [requestList, setrequestList] = useState<any>({})
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [requestLoading, setRequestLoading] = useState(false)
  useEffect(() => {
    fetch(
      `https://9umubpcrfa.execute-api.eu-west-1.amazonaws.com/prod/admin/all_transfers`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data)
          setrequestList(data.data)
          setLoading(false)
        })
      } else if (res.status === 403 || res.status === 429) {
        logOut()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  const replyTo = (action: string) => {
    setRequestLoading(true)
    fetch(
      `https://9umubpcrfa.execute-api.eu-west-1.amazonaws.com/prod/admin/transfer_status`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
        body: JSON.stringify({
          transfer_id: selectedUser.trf_id,
          transfer_status: action,
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data)

          notify('success', data.message)
          setRequestLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('success', data.message)
          setRequestLoading(false)
        })
      }
    })
  }

  return loading ? (
    <div className='loader1'>
      <img src={Spinner} alt='' />
    </div>
  ) : (
    <div className='foreign'>
      <div className='left'>
        <div className='top'>
          <div className='tleft'>
            <h2>Foreign Transfer Request</h2>
            <p>All transfer request will all appear here.</p>
          </div>
          <div className='tright'>
            {/* <select name='' id=''>
              <option value=''>Aproved </option>
            </select> */}
            <input type='search' name='' id='' placeholder='Search...' />
          </div>
        </div>
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th>SW Username</th>
                <th>CURRENCY</th>
                <th>AMOUNT</th>
                <th>Tarrif</th>
                <th>BANK NAME</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {requestList.map((item: any) => (
                <tr
                  key={item.trf_id}
                  onClick={() => setSelectedUser(item)}
                  className={
                    selectedUser && selectedUser.trf_id === item.trf_id
                      ? 'active'
                      : ''
                  }
                >
                  <td>{item.beneficiary.user_username}</td>
                  <td>{item.currency}</td>
                  <td>{parseInt(item.amount).toLocaleString()}</td>
                  <td>{item.tariff_group}</td>
                  <td>{item.beneficiary.bank_name}</td>
                  <td>
                    <button className={item.status}>{item.status}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='right'>
        {selectedUser ? (
          <>
            <div className='top'>
              <p>Are you approving, this beneficiary?</p>
              <div className='buttons'>
                {requestLoading ? (
                  <div className='inm'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <>
                    {selectedUser.status === 'pending' ? (
                      <button
                        className='accept'
                        onClick={() => replyTo('processing')}
                      >
                        PROCESS
                      </button>
                    ) : selectedUser.status === 'completed' ? (
                      <button className='accept'>COMPLETED</button>
                    ) : selectedUser.status === 'processing' ? (
                      <button
                        className='accept'
                        onClick={() => replyTo('completed')}
                      >
                        COMPLETE
                      </button>
                    ) : (
                      <button className='declinex'>Failed</button>
                    )}
                    {(selectedUser.status === 'pending' ||
                      selectedUser.status === 'processing') && (
                      <button
                        className='declinex'
                        onClick={() => replyTo('failed')}
                      >
                        DECLINE
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='restX'>
              <div className='rest-head'>
                <div className='flec'>
                  <p>
                    {selectedUser.beneficiary.ac_no ? 'Account Number' : 'IBAN'}
                  </p>
                  <button>{selectedUser.status}</button>
                </div>
                <div className='ac'>
                  <h1>
                    {selectedUser.beneficiary.ac_no
                      ? selectedUser.beneficiary.ac_no
                      : selectedUser.beneficiary.iban}
                  </h1>
                </div>
                <div className='rou'>
                  <p>
                    {' '}
                    {selectedUser.beneficiary.sort_code !== null
                      ? 'Sort Code'
                      : selectedUser.beneficiary.routing_code !== null
                      ? 'Routing Code'
                      : selectedUser.beneficiary.cnaps !== null
                      ? 'CNAPS'
                      : selectedUser.beneficiary.routing_no_aba !== null
                      ? 'Routing no / Aba code'
                      : selectedUser.beneficiary.swift !== null
                      ? 'Swift'
                      : ''}
                  </p>
                  <h2>
                    {selectedUser.beneficiary.sort_code !== null
                      ? selectedUser.beneficiary.sort_code
                      : selectedUser.beneficiary.routing_code !== null
                      ? selectedUser.beneficiary.routing_code
                      : selectedUser.beneficiary.cnaps !== null
                      ? selectedUser.beneficiary.cnaps
                      : selectedUser.beneficiary.routing_no_aba !== null
                      ? selectedUser.beneficiary.routing_no_aba
                      : selectedUser.beneficiary.swift !== null
                      ? selectedUser.beneficiary.swift
                      : ''}
                  </h2>
                </div>
                <div className='buttons'>
                  <button>view Invoice</button>
                  <button>Download Invoice</button>
                </div>
              </div>
              <div className='rest-body'>
                <div className='card'>
                  <p>Date</p>
                  <h2>
                    <span>*</span>
                    {new Date(selectedUser.create_time).toDateString()}
                  </h2>
                </div>
                <div className='card'>
                  <p>Time</p>
                  <h2>
                    <span>*</span>
                    {new Date(selectedUser.create_time).toLocaleTimeString()}
                  </h2>
                </div>
                <div className='card'>
                  <p>Charges</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.charges}
                  </h2>
                </div>
                <div className='card'>
                  <p>Total bill in NGN</p>
                  <h2>
                    <span>*</span>
                    NGN {parseInt(selectedUser.total_bill_ngn).toLocaleString()}
                  </h2>
                </div>
                <div className='card'>
                  <p>Naration</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.narration}
                  </h2>
                </div>
                <div className='card'>
                  <p>Beneficiary Name</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.company_name}
                  </h2>
                </div>
                <div className='card'>
                  <p>Bank</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.bank_name}
                  </h2>
                </div>
                <div className='card'>
                  <p>Email</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.recipient_email}
                  </h2>
                </div>
                <div className='card'>
                  <p>Address</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.beneficiary_address}
                  </h2>
                </div>
                <div className='card'>
                  <p>City</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.beneficiary_city}
                  </h2>
                </div>
                <div className='card'>
                  <p>Country</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary.country}
                  </h2>
                </div>
                {selectedUser.beneficiary.intermediary && (
                  <>
                    <div className='card'>
                      <p>Intermediary bank name</p>
                      <h2>{selectedUser.beneficiary.intermediary_bank_name}</h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary bank address</p>
                      <h2>
                        {selectedUser.beneficiary.intermediary_bank_address}
                      </h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary ac country</p>
                      <h2>
                        {selectedUser.beneficiary.intermediary_ac_country}
                      </h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary label tag</p>
                      <h2>{selectedUser.beneficiary.intermediary_label_tag}</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='empty'>
              <p>
                Selected Foreign beneficiary Approval details will be displayed
                here.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ForeignTrans
