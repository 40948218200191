import React, { useState } from 'react'
import './Profile.scss'
import {
  FaSearch,
  FaDownload,
  FaCaretDown,
  FaCaretUp,
  FaTimes,
} from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../../Images/spinner.gif'
import Loader from '../../../../Images/loder.gif.gif'

interface Props {
  loading: string
  notify: any
  businessData: {
    count: number
    page_number: number
    total_pages: number
    results: [
      {
        user: string
        business_name: string
        username: string
        create_date: string
        approved: boolean
        rejected: boolean
        registered: boolean
        is_business: string
      }
    ]
  }
  businessList: [
    {
      user: string
      business_name: string
      username: string
      create_date: string
      approved: boolean
      rejected: boolean
      registered: boolean
      is_business: string
    }
  ]
  setBusinessList: React.Dispatch<any>
  setLoading: React.Dispatch<any>
  setBusinessData: React.Dispatch<any>
}
function Profile({
  loading,
  setLoading,
  businessData,
  setBusinessData,
  notify,
  businessList,
  setBusinessList,
}: Props) {
  const navigate = useNavigate()
  const [showId, setshowId] = useState<string | boolean>('false')
  const [userName, setUserName] = useState<string | boolean>('false')
  const [businessName, setBusinessName] = useState<string | boolean>('false')
  const [date, setDate] = useState<string | boolean>('false')
  const [approved, setApproved] = useState<string | boolean>('false')
  const [declineModal, setDeclineModal] = useState(false)
  const [acceptModal, setAcceptModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<{} | any>({})
  const [Reason, setReason] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const paginationClick = (num: number) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/accounts/business_profile/?page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBusinessData(data)
          setBusinessList(data.results)
          setLoading(false)
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }
  function formatAMPM(date: any) {
    var hours = date.slice(0, 2)
    var minutes = date.slice(3, 5)
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  const declineRequest = (id: string) => {
    const findUser = businessData.results.find((user) => user.user === id)
    setDeclineModal(true)
    setSelectedUser(findUser)
  }
  const acceptRequest = (id: string) => {
    const findUser = businessData.results.find((user) => user.user === id)
    setAcceptModal(true)
    setSelectedUser(findUser)
  }
  const onDecline = () => {
    setFormLoading(true)

    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_approve/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
      body: JSON.stringify({
        user_id: selectedUser.user,
        action: 'reject',
        reason: Reason,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            window.location.reload()
            setFormLoading(false)
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  const onAccept = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_approve/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
      body: JSON.stringify({
        user_id: selectedUser.user,
        action: 'approve',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            window.location.reload()
            setFormLoading(false)
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  return loading ? (
    <div className='loader1'>
      <img src={Spinner} alt='' />
    </div>
  ) : (
    <div className='profile'>
      {acceptModal && (
        <div className='decline'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setAcceptModal(false)} />
            </div>
            <h1>Are you sure you want to accept request</h1>
            {formLoading ? (
              <div className='buttons'>
                <img src={Loader} />
              </div>
            ) : (
              <div className='buttons'>
                <button onClick={() => setAcceptModal(false)}>Cancel</button>
                <button onClick={onAccept}>Approve</button>
              </div>
            )}
          </div>
        </div>
      )}
      {declineModal && (
        <div className='decline'>
          <div className='modal'>
            <div className='close'>
              <FaTimes onClick={() => setDeclineModal(false)} />
            </div>
            <h1>Provide Reason for declined request</h1>
            <div className='inputty'>
              <div className='count'>
                <p>0/128</p>
              </div>
              <div className='texta'>
                <div className='text_top'></div>
                <textarea
                  name=''
                  id=''
                  cols={30}
                  rows={10}
                  placeholder='Reason Text'
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
              {formLoading ? (
                <div className='buttons'>
                  <img src={Loader} />
                </div>
              ) : (
                <button className='button' onClick={onDecline}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='top'>
        <div className='left'>
          <div className='search'>
            <div className='input'>
              <input type='text' placeholder='Search' />
            </div>
            <div className='button'>
              <FaSearch />
            </div>
          </div>
        </div>
        <div className='right'>
          <div className='download'>
            <FaDownload />
          </div>
        </div>
      </div>
      <div className='stats'>
        <h1>
          {businessData.page_number === 1
            ? 1
            : businessData.page_number * 10 + 1 - 10}
          - {businessData.page_number * 10}
          <span> of</span> {businessData.count}
        </h1>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>
                <div
                  className='head'
                  onClick={() => {
                    setshowId(!showId)
                    if (!userName) {
                      setUserName(!userName)
                    }
                    if (!businessName) {
                      setBusinessName(!businessName)
                    }
                    if (!date) {
                      setDate(!date)
                    }
                    if (!approved) {
                      setApproved(!approved)
                    }
                  }}
                >
                  <p>User ID</p>
                </div>
              </th>
              <th>
                <div
                  className='head'
                  onClick={() => {
                    setUserName(!userName)
                    if (!showId) {
                      setshowId(!showId)
                    }
                    if (!businessName) {
                      setBusinessName(!businessName)
                    }
                    if (!date) {
                      setDate(!date)
                    }
                    if (!approved) {
                      setApproved(!approved)
                    }
                  }}
                >
                  <p>Username</p>
                  {userName ? <FaCaretDown /> : <FaCaretUp />}
                </div>
                {!userName && (
                  <div className='tail'>
                    <ul>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              a.username.localeCompare(b.username)
                            )
                          )
                          setUserName(!userName)
                        }}
                      >
                        Asc
                      </li>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              b.username.localeCompare(a.username)
                            )
                          )
                          setUserName(!userName)
                        }}
                      >
                        Dsc
                      </li>
                    </ul>
                  </div>
                )}
              </th>
              <th>
                <div
                  className='head'
                  onClick={() => {
                    setBusinessName(!businessName)
                    if (!showId) {
                      setshowId(!showId)
                    }
                    if (!userName) {
                      setUserName(!userName)
                    }
                    if (!date) {
                      setDate(!date)
                    }
                    if (!approved) {
                      setApproved(!approved)
                    }
                  }}
                >
                  <p>Business Name</p>
                  {businessName ? <FaCaretDown /> : <FaCaretUp />}
                </div>
                {!businessName && (
                  <div className='tail'>
                    <ul>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              a.business_name.localeCompare(b.business_name)
                            )
                          )
                          setBusinessName(!businessName)
                        }}
                      >
                        Asc
                      </li>
                      <li
                        onClick={() => {
                          setBusinessName(!businessName)
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              b.business_name.localeCompare(a.business_name)
                            )
                          )
                        }}
                      >
                        Dsc
                      </li>
                    </ul>
                  </div>
                )}
              </th>
              <th>
                <div className='head'>
                  <p>Business Type</p>
                </div>
              </th>
              <th>
                <div
                  className='head'
                  onClick={() => {
                    setDate(!date)
                    if (!showId) {
                      setshowId(!showId)
                    }
                    if (!userName) {
                      setUserName(!userName)
                    }
                    if (!businessName) {
                      setBusinessName(!businessName)
                    }
                    if (!approved) {
                      setApproved(!approved)
                    }
                  }}
                >
                  <p>Date Created</p>
                  {date ? <FaCaretDown /> : <FaCaretUp />}
                </div>
                {!date && (
                  <div className='tail'>
                    <ul>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              b.create_date.localeCompare(a.create_date)
                            )
                          )
                          setDate(!date)
                        }}
                      >
                        Asc
                      </li>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.sort((a, b) =>
                              a.create_date.localeCompare(b.create_date)
                            )
                          )
                          setDate(!date)
                        }}
                      >
                        Dsc
                      </li>
                    </ul>
                  </div>
                )}
              </th>
              <th>
                <div
                  className='head'
                  onClick={() => {
                    setApproved(!approved)
                    if (!showId) {
                      setshowId(!showId)
                    }
                    if (!userName) {
                      setUserName(!userName)
                    }
                    if (!businessName) {
                      setBusinessName(!businessName)
                    }
                    if (!date) {
                      setDate(!date)
                    }
                    if (!approved) {
                      setApproved(!approved)
                    }
                  }}
                >
                  <p>Activation Request</p>
                  {approved ? <FaCaretDown /> : <FaCaretUp />}
                </div>
                {!approved && (
                  <div className='tail'>
                    <ul>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.filter(
                              (a) => a.approved === true
                            )
                          )
                        }}
                      >
                        Approved
                      </li>
                      <li
                        onClick={() => {
                          setBusinessList(
                            businessData.results.filter(
                              (a) => a.approved === false
                            )
                          )
                        }}
                      >
                        Not-Approved
                      </li>
                    </ul>
                  </div>
                )}
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody
            onClick={() => {
              if (!showId) {
                setshowId(!showId)
              }
              if (!userName) {
                setUserName(!userName)
              }
              if (!businessName) {
                setBusinessName(!businessName)
              }
              if (!date) {
                setDate(!date)
              }
              if (!approved) {
                setApproved(!approved)
              }
            }}
          >
            {businessList.map((item) => (
              <tr key={item.user}>
                <td>{item.user}</td>
                <td>{item.username}</td>
                <td>{item.business_name} </td>
                <td>{item.registered ? 'Registered' : 'Not Registered'}</td>
                <td>
                  {new Date(item.create_date).toDateString()}, {''}
                  {formatAMPM(item.create_date.slice(11, 16))}
                </td>
                <td className='buttons'>
                  {item.approved ? (
                    <button className={'actives'}>Approved</button>
                  ) : (
                    <>
                      <button
                        className={item.rejected === true ? 'active' : ''}
                        onClick={() => {
                          if (!item.rejected) {
                            declineRequest(item.user)
                          }
                        }}
                      >
                        {item.rejected === true ? 'Denied' : 'Deny'}
                      </button>

                      {!item.rejected && (
                        <button onClick={() => acceptRequest(item.user)}>
                          Approve
                        </button>
                      )}
                    </>
                  )}
                </td>
                <td>
                  <MdOpenInNew
                    onClick={() => navigate(`/dashboard/profile/${item.user}`)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className='pagination'
        onClick={() => {
          if (!showId) {
            setshowId(!showId)
          }
          if (!userName) {
            setUserName(!userName)
          }
          if (!businessName) {
            setBusinessName(!businessName)
          }
          if (!date) {
            setDate(!date)
          }
          if (!approved) {
            setApproved(!approved)
          }
        }}
      >
        <ul>
          {[...Array(businessData.total_pages)].map((item, i) => (
            <li
              key={i + 1}
              onClick={() => paginationClick(i + 1)}
              className={businessData.page_number === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Profile
