import React, { useState } from 'react'
import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from './Pages/Dashboard/Dashboard'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './Pages/Auth/Login'
import ProtectedRoute from './ProtectedRoute'

type UserObj = {
  token: string
}
function App() {
  const notify = (type: string, message: string) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  const [loggedIn, setLoggedIn] = useState(false)

  const logUserIn = (user: UserObj) => {
    sessionStorage.setItem('userId', user.token)
    setLoggedIn(true)
  }
  const logOut403 = () => {
    sessionStorage.removeItem('userId')
    notify(
      'warn',
      'Ooops! You have logged in on a different device. This session has expired.'
    )
    window.location.replace('/')
  }
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<Login notify={notify} Login={logUserIn} />} />
        <Route element={<ProtectedRoute notify={notify} loggedIn={loggedIn} />}>
          <Route
            path='/dashboard/:page'
            element={<Dashboard notify={notify} logOut={logOut403} />}
          />
          <Route
            path='/dashboard/:page/:id'
            element={<Dashboard notify={notify} logOut={logOut403} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
