import React, { useEffect, useState } from 'react'
import './Home.scss'
import { TbReceiptTax } from 'react-icons/tb'
import { RxCardStackPlus } from 'react-icons/rx'
import {
  MdOutlineMarkEmailRead,
  MdOutlinePhonelinkLock,
  MdCreditCardOff,
  MdCreditCard,
} from 'react-icons/md'
import { BiUser } from 'react-icons/bi'
import { FcCancel } from 'react-icons/fc'
import Spinner from '../../../../Images/spinner.gif'

function Home() {
  const [loading, setLoading] = useState<string | any>(true)
  const [stats, setStats] = useState<any>({})
  const [aboveClearance, setAboveClearance] = useState(false)
  useEffect(() => {
    fetch(
      `https://9umubpcrfa.execute-api.eu-west-1.amazonaws.com/prod/admin/admin`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setStats(data.data)
          setLoading(false)
        })
      } else if (res.status === 403 || res.status === 401) {
        setAboveClearance(true)
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader1'>
      <img src={Spinner} alt='' />
    </div>
  ) : (
    <div className='home'>
      {aboveClearance ? (
        <div className='error'>
          <h1>You do not have the Permission to view this section</h1>
        </div>
      ) : (
        <div className='home_card'>
          <div className='card'>
            <div className='left dif'>
              <div className='round'>
                <BiUser />
              </div>
            </div>

            <div className='right'>
              <p>Total Users</p>
              <h2>{stats.total_users.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left '>
              <div className='round'>
                <MdOutlineMarkEmailRead />
              </div>
            </div>
            <div className='right'>
              <p>Email Verified</p>
              <h2>{stats.email_verified.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left dif'>
              <div className='round'>
                <MdOutlinePhonelinkLock />
              </div>
            </div>
            <div className='right'>
              <p>Phone Verified</p>
              <h2>{stats.phone_verified.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <div className='round'>
                <TbReceiptTax />
              </div>
            </div>
            <div className='right'>
              <p>Total Invoices</p>
              <h2>{stats.total_invoices.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left dif'>
              <div className='round'>
                <RxCardStackPlus />
              </div>
            </div>
            <div className='right'>
              <p>Topup Requests</p>
              <h2>{stats.topup_requests.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <div className='round'>
                <FcCancel />
              </div>
            </div>
            <div className='right'>
              <p>Failed Topup Requests</p>
              <h2>{stats.failed_topup_requests.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left dif'>
              <div className='round'>
                <MdCreditCard />
              </div>
            </div>
            <div className='right'>
              <p>Card Requests</p>
              <h2>{stats.card_requests.toLocaleString()}</h2>
            </div>
          </div>
          <div className='card'>
            <div className='left '>
              <div className='round'>
                <MdCreditCardOff />
              </div>
            </div>
            <div className='right'>
              <p>Failed Card Requests</p>
              <h2>{stats.failed_card_requests.toLocaleString()}</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
