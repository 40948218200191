import React from 'react'
import './Sidebar.scss'
import Logo from '../../../../Images/logo.png'
import { FaUserTie, FaCreditCard, FaHome, FaUserPlus } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import { TbRecharging } from 'react-icons/tb'
import { RiMoneyCnyCircleFill } from 'react-icons/ri'
import { useGlobalContext } from '../../../../Context/Context'
import { Link } from 'react-router-dom'

interface props {
  page: string | undefined
}
function Sidebar({ page }: props) {
  const { toggleSidebar } = useGlobalContext()

  return (
    <div className={toggleSidebar ? 'sidebar' : 'sidebar active'}>
      <div className='top'>
        <img src={Logo} alt='' />
      </div>
      <div className='sidebar_body'>
        <ul>
          <Link to='/dashboard/home'>
            <li className={page?.includes('home') ? 'active' : ''}>
              <FaHome />
              <p>Dashboard</p>
            </li>
          </Link>
          <Link to='/dashboard/profiles'>
            <li className={page?.includes('profile') ? 'active' : ''}>
              <FaUserTie />
              <p>Business Profile</p>
            </li>
          </Link>
          <Link to='/dashboard/foreign-beneficiary'>
            <li
              className={page?.includes('foreign-beneficiary') ? 'active' : ''}
            >
              <FaUserPlus />
              <p>Foreign Beneficiary</p>
            </li>
          </Link>
          <Link to='/dashboard/foreign-transfer'>
            <li className={page?.includes('foreign-transfer') ? 'active' : ''}>
              <RiMoneyCnyCircleFill />
              <p>Foreign Transfer</p>
            </li>
          </Link>
          <Link to='/dashboard/card-request'>
            <li className={page?.includes('card-request') ? 'active' : ''}>
              <FaCreditCard />
              <p>Card Request</p>
            </li>
          </Link>
          <Link to='/dashboard/card-topup'>
            <li className={page?.includes('card-topup') ? 'active' : ''}>
              <TbRecharging />
              <p>Card TopUp</p>
            </li>
          </Link>

          <li
            onClick={() => {
              sessionStorage.clear()
              window.location.replace('/')
            }}
          >
            <FiLogOut />
            <p>Log out</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
