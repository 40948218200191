import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Login.scss'
import Logo from '../../Images/logo1.png'
import Man from '../../Images/man.png'
import Icon1 from '../../Images/Group1.png'
import Icon2 from '../../Images/Group2.png'
import Icon3 from '../../Images/Group3.png'
import Angle from '../../Images/angle.png'
import Loader from '../../Images/loder.gif.gif'

interface Props {
  notify: any
  Login: any
}
type FormValues = {
  username: string
  password: string
}
function Login({ notify, Login }: Props) {
  const [shown, setShown] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>()
  const onRegister = (formData: {}) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/login/`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
      }),
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        } else if (res.status === 400) {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify(
          'error',
          'An error occured. Unable to reach server. Please check your internet connection.'
        )
        setFormLoading(false)
      })
      .then((data) => {
        setFormLoading(false)
        if (data.is_admin) {
          Login({ ...data })
          setTimeout(() => {
            window.location.replace(`/dashboard/home`)
          }, 2000)
          notify('success', `welcome Admin ${data.user}`)
        } else {
          notify('error', `This site is above your clearance, please exit`)
        }
      })
  }
  return (
    <section className='auth'>
      <div className='logo'>
        <img src={Logo} alt='' />
      </div>
      <div className='wrap'>
        <div className='left'>
          <div className='icons'>
            <div className='can'>
              <img src={Icon1} alt='' />
            </div>
            <div className='can edit'>
              <img src={Icon2} alt='' />
            </div>
            <div className='can'>
              <img src={Icon3} alt='' />
            </div>
          </div>
          <div className='man'>
            <img src={Man} alt='' />
          </div>
        </div>
        <div className='right'>
          <div className='angle'>
            <div>
              <img src={Angle} alt='' />
            </div>
          </div>
          <div className='form'>
            <div className='head'>
              <h1>Sign In</h1>
              <p>Welcome Back</p>
            </div>
            <form className='auth-form' onSubmit={handleSubmit(onRegister)}>
              <div className='card'>
                <p>Username</p>
                <input
                  type='text'
                  {...register('username', {
                    required: 'Username is required',
                  })}
                  name='username'
                />
                {errors.username && (
                  <h6 className='vError'>{errors.username.message}</h6>
                )}
              </div>
              <div className='card password'>
                <p>Password</p>
                <div>
                  <input
                    type={shown ? 'text' : 'password'}
                    {...register('password', {
                      required: 'Provide a password',
                      minLength: {
                        value: 7,
                        message: 'Password must have at least 8 characters',
                      },
                      pattern: {
                        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                        message:
                          'Passwords have to contain at least one uppercase and one lower case character and a number',
                      },
                    })}
                    name='password'
                  />
                  <p onClick={() => setShown(!shown)}>
                    {shown ? <span>hide</span> : <span>show</span>}
                  </p>
                </div>
                {errors.password && (
                  <h6 className='vError'>{errors.password.message}</h6>
                )}
              </div>

              <div className='card'>
                {formLoading ? (
                  <div className='loaders'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <button
                    type='submit'
                    value={formLoading ? '.....' : 'SUBMIT'}
                  >
                    Sign In
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
