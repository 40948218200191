import React, { useState, useEffect } from 'react'
import './Topbar.scss'
import { FaBars, FaBell } from 'react-icons/fa'
import { useGlobalContext } from '../../../../Context/Context'

function Topbar() {
  const [loading, setLoading] = useState<string | any>(true)
  const [user, setUser] = useState<{} | any>({})
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/get_profile/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUser(data)
          setLoading(false)
        })
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])

  const { handleSidebarToggle } = useGlobalContext()
  return (
    <div className='topbar'>
      <div className='left'>
        <FaBars onClick={handleSidebarToggle} />
      </div>
      <div className='right'>
        <div className='bell'>
          <FaBell />
        </div>
        <div className='imagecon'>
          {loading ? (
            <h1>!!</h1>
          ) : (
            <h1>
              {' '}
              {user.first_name === null || user.surname === null ? (
                <>
                  {user.username.charAt(0)}
                  {user.username.charAt(1)}
                </>
              ) : (
                <>
                  {user.first_name.charAt(0)}
                  {user.surname.charAt(0)}
                </>
              )}
            </h1>
          )}
        </div>
        <div className='tag'>
          <h2>
            {user.first_name} {user.surname}
          </h2>
          <p>{user.occupation}</p>
        </div>
      </div>
    </div>
  )
}

export default Topbar
