import React, { useState } from 'react'
import './CardRequest.scss'
import {
  FaSearch,
  FaDownload,
  FaCaretDown,
  FaCaretUp,
  FaTimes,
} from 'react-icons/fa'

const List = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

function CardRequest({
  CardRequest1,
  CardRequest0,
  cardLoading,
  cardLoading1,
}: any) {
  const [failList0, setFailList0] = useState<any>([])
  const [selectedNo, setSelectedNo] = useState<any>({})

  const [tab, setTab] = useState('level0')

  return (
    <div className='card-request'>
      <div className='top'>
        <div className='left'>
          <div className='search'>
            <div className='input'>
              <input type='text' placeholder='Search Username/Request ID' />
            </div>
            <div className='button'>
              <FaSearch />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='counter'>
        <div className='card'>
          <h2>31,520</h2>
          <p>Total Request</p>
        </div>
        <div className='card'>
          <h2>10,120</h2>
          <p>Request Cleared</p>
        </div>
        <div className='card'>
          <h2>20,020</h2>
          <p>Request Pending</p>
        </div>
        <div className='card'>
          <h2>1,380</h2>
          <p>Inactive Users</p>
        </div>
      </div> */}
      <div className='switch_button'>
        <button
          title='Failures due to error charging a user card or splitting charge into appropriate accounts.'
          className={tab === 'level0' ? 'ac' : ''}
          onClick={() => setTab('level0')}
        >
          {' '}
          Level 0
        </button>
        <button
          title='Failures due to error creating a card or toping it up after a successful charging of the user/business walle. .'
          className={tab === 'level1' ? 'ac' : ''}
          onClick={() => setTab('level1')}
        >
          {' '}
          Level 1
        </button>
      </div>
      {cardLoading ? (
        <div>Loading</div>
      ) : (
        <>
          {tab === 'level0' && (
            <div className='table'>
              <div className='head'>
                <div className='name'>
                  <p>Username</p>
                </div>
                <div className='date'>
                  <p>Request Date </p>
                </div>
                <div className='bill'>
                  <p>Total Bill (NGN) </p>
                </div>
                <div className='usd'>
                  <p>USD Amount </p>
                </div>
                <div className='number'>
                  <p>Wallet Account Number</p>
                </div>
              </div>
              <div className='body'>
                {CardRequest0.map((item: any) => (
                  <div className='row-cover'>
                    <div
                      className={
                        selectedNo.bill_request_id === item.bill_request_id
                          ? 'row active'
                          : 'row'
                      }
                      onClick={() => {
                        if (
                          selectedNo.bill_request_id === item.bill_request_id
                        ) {
                          setSelectedNo(0)
                        } else {
                          setSelectedNo(item)
                        }
                      }}
                    >
                      <div className='name'>
                        <p>{item.username} </p>
                      </div>
                      <div className='date'>
                        <p>{item.created.toLocaleString()} </p>
                      </div>
                      <div className='bill'>
                        <p>{item.total_bill_ngn.toLocaleString()} </p>
                      </div>
                      <div className='usd'>
                        <p>{item.amount.toLocaleString()}</p>
                      </div>
                      <div className='number'>
                        <p>{item.main_wallet_ac_number}</p>
                      </div>
                    </div>
                    <div
                      className={
                        selectedNo === item ? 'row-body active' : 'row-body'
                      }
                    >
                      <div className='wrap'>
                        <div className='card'>
                          <h2>User Id</h2>
                          <div className='input'>
                            <p>{item.user_id}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Alias</h2>
                          <div className='input'>
                            <p>{item.alias}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Wallet Debited Successfully</h2>
                          <div className='input'>
                            <p>{`${item.wallet_debited_successfully}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Income Account Funded Successfully</h2>
                          <div className='input'>
                            <p>{`${item.card_income_account_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Sudo Amount</h2>
                          <div className='input'>
                            <p># {item.sudo_charge}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Total Attempts</h2>
                          <div className='input'>
                            <p>{item.total_attemps}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Failure Reason</h2>
                          <div className='input'>
                            <p>{item.fail_reason}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Bridge Account Funded Successfully</h2>
                          <div className='input'>
                            <p>{`${item.bridge_ac_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Card Settlement Account Funded Successfully </h2>
                          <div className='input'>
                            <p>{`${item.card_income_account_funded}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Request ID </h2>
                          <div className='input'>
                            <p>{item.bill_request_id}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Request Enqueue </h2>
                          <div className='input'>
                            <p>{`${item.request_enqueued}`}</p>
                          </div>
                        </div>
                        <div className='card'>
                          <h2>Narration</h2>
                          <div className='input'>
                            <p>{item.narration}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {tab === 'level1' && (
            <div className='table'>
              <div className='head'>
                <div className='name'>
                  <p>Username</p>
                </div>
                <div className='date'>
                  <p>Request Date </p>
                </div>
                <div className='bill'>
                  <p>Total Bill (NGN) </p>
                </div>
                <div className='usd'>
                  <p>USD Amount </p>
                </div>
                <div className='number'>
                  <p>Wallet Account Number</p>
                </div>
              </div>
              <div className='body'>
                {!cardLoading1 ? (
                  <>
                    {CardRequest1.map((item: any) => (
                      <div className='row-cover'>
                        <div
                          className={
                            selectedNo.bill_request_id === item.bill_request_id
                              ? 'row active'
                              : 'row'
                          }
                          onClick={() => {
                            if (
                              selectedNo.bill_request_id ===
                              item.bill_request_id
                            ) {
                              setSelectedNo(0)
                            } else {
                              setSelectedNo(item)
                            }
                          }}
                        >
                          <div className='name'>
                            <p>{item.username} </p>
                          </div>
                          <div className='date'>
                            <p>{item.created.toLocaleString()} </p>
                          </div>
                          <div className='bill'>
                            <p>{item.total_bill_ngn.toLocaleString()} </p>
                          </div>
                          <div className='usd'>
                            <p>{item.amount.toLocaleString()}</p>
                          </div>
                          <div className='number'>
                            <p>{item.main_wallet_ac_number}</p>
                          </div>
                        </div>
                        <div
                          className={
                            selectedNo === item ? 'row-body active' : 'row-body'
                          }
                        >
                          <div className='wrap'>
                            <div className='card'>
                              <h2>User Id</h2>
                              <div className='input'>
                                <p>{item.user_id}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Card Alias</h2>
                              <div className='input'>
                                <p>{item.alias}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Wallet Debited Successfully</h2>
                              <div className='input'>
                                <p>{`${item.wallet_debited_successfully}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Card Income Account Funded Successfully</h2>
                              <div className='input'>
                                <p>{`${item.card_income_account_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Sudo Amount</h2>
                              <div className='input'>
                                <p># {item.sudo_charge}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Total Attempts</h2>
                              <div className='input'>
                                <p>{item.total_attemps}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Failure Reason</h2>
                              <div className='input'>
                                <p>{item.fail_reason}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Bridge Account Funded Successfully</h2>
                              <div className='input'>
                                <p>{`${item.bridge_ac_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>
                                Card Settlement Account Funded Successfully{' '}
                              </h2>
                              <div className='input'>
                                <p>{`${item.card_income_account_funded}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Request ID </h2>
                              <div className='input'>
                                <p>{item.bill_request_id}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Request Enqueue </h2>
                              <div className='input'>
                                <p>{`${item.request_enqueued}`}</p>
                              </div>
                            </div>
                            <div className='card'>
                              <h2>Narration</h2>
                              <div className='input'>
                                <p>{item.narration}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CardRequest
