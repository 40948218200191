import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Profile from './components/Business-Profile/Profile'
import Sidebar from './components/Sidebar/Sidebar'
import './Dashboard.scss'
import { useGlobalContext } from '../../Context/Context'
import Topbar from './components/Topbar/Topbar'
import SingleProfile from './components/Business-Profile/Single-profile'
import CardRequest from './components/Card-Request/CardRequest'
import Home from './components/Home/Home'
import CardTopup from './components/Card-topup/CardRequest'
import ForeignBene from './components/ForeignBene/ForeignBene'
import ForeignTrans from './components/ForeignTrans/ForeignTrans'

interface Props {
  notify: any
  logOut: any
}
function Dashboard({ notify, logOut }: Props) {
  const { page } = useParams()
  const [loading, setLoading] = useState<string | any>(true)
  const [businessData, setBusinessData] = useState<{} | any>({})
  const [businessList, setBusinessList] = useState<[] | any>([])
  const [CardRequest0, setCardRequest0] = useState([])
  const [CardTopup0, setCardTopup0] = useState([])
  const [CardRequest1, setCardRequest1] = useState([])
  const [CardTopup1, setCardTopup1] = useState([])
  const [cardLoading, setCardLoading] = useState(true)
  const [cardLoading1, setCardLoading1] = useState(true)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_profile/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBusinessData(data)
          setBusinessList(data.results)
          setLoading(false)
        })
      } else if (res.status === 403 || res.status === 429) {
        logOut()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/failed_requests_0/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCardRequest0(data.data.failed_bills_card_creation)
          setCardTopup0(data.data.failed_bills_card_topup)
          setCardLoading(false)
        })
      } else if (res.status === 403 || res.status === 429) {
        logOut()
      } else {
        res.json().then((data) => {
          setCardLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/failed_requests_1/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCardRequest1(data.data.failed_card_creation)
          setCardTopup1(data.data.failed_card_topup)
          setCardLoading1(false)
        })
      } else if (res.status === 403 || res.status === 429) {
        logOut()
      } else {
        res.json().then((data) => {
          setCardLoading1(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])

  const DashboardPage = () => {
    switch (page) {
      case 'home':
        return <Home />
      case 'profiles':
        return (
          <Profile
            loading={loading}
            businessData={businessData}
            setBusinessData={setBusinessData}
            setLoading={setLoading}
            notify={notify}
            businessList={businessList}
            setBusinessList={setBusinessList}
          />
        )
      case 'profile':
        return (
          <SingleProfile
            listLoading={loading}
            businessUsers={businessData}
            notify={notify}
          />
        )
      case 'card-request':
        return (
          <CardRequest
            CardRequest0={CardRequest0}
            CardRequest1={CardRequest1}
            cardLoading={cardLoading}
            cardLoading1={cardLoading1}
          />
        )
      case 'card-topup':
        return (
          <CardTopup
            CardTopup0={CardTopup0}
            CardTopup1={CardTopup1}
            cardLoading={cardLoading}
            cardLoading1={cardLoading1}
          />
        )
      case 'foreign-beneficiary':
        return <ForeignBene notify={notify} logOut={logOut} />
      case 'foreign-transfer':
        return <ForeignTrans notify={notify} logOut={logOut} />

      default:
        return <h1>Page not found</h1>
    }
  }
  const { handleSidebarToggle, toggleSidebar } = useGlobalContext()
  return (
    <div className='dashboard'>
      <div className='dashboard_body'>
        <Sidebar page={page} />
        <main
          className='dashboard_pages'
          onClick={() => {
            if (!toggleSidebar) {
              handleSidebarToggle()
            }
          }}
        >
          <Topbar />
          <section className={page?.includes('foreign') ? 'rest dif' : 'rest'}>
            {DashboardPage()}
          </section>
        </main>
      </div>
    </div>
  )
}

export default Dashboard
