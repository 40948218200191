import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import Images from '../../../../Images/CAC Certificate.png'
import {
  AiOutlineClose,
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
} from 'react-icons/ai'
import { MdOutline360 } from 'react-icons/md'
import './Profile.scss'
import { useNavigate } from 'react-router-dom'

interface Props {
  setOpenImage: React.Dispatch<React.SetStateAction<boolean>>
  businessData: {
    director_id: string
    utility_bill: string
    cac_cert: string
    board_res_letter: string
  }
}
function SwiperProfile({ setOpenImage, businessData }: Props) {
  const [DirectorRotate, setDirectorRotate] = useState('')
  const [UtilityRotate, setUtilityRotate] = useState('')
  const [CacRotate, setCacRotate] = useState('')
  const [BoardRotate, setBoardRotate] = useState('')
  const navigate = useNavigate()
  return (
    <div className='swiperprol'>
      <div className='top'>
        <AiOutlineClose onClick={() => setOpenImage(false)} />
      </div>
      <Swiper navigation={true} modules={[Navigation]} className='mySwiper'>
        <SwiperSlide>
          <div>
            <h3>Director ID</h3>
            <div className='icons'>
              <AiOutlineRotateLeft onClick={() => setDirectorRotate('left')} />
              <MdOutline360
                onClick={() => {
                  setDirectorRotate('upside')
                }}
              />
              <AiOutlineRotateRight
                onClick={() => setDirectorRotate('right')}
              />
            </div>
            <img
              className={DirectorRotate}
              src={businessData.director_id}
              alt=''
            />
            <div className='buttons'>
              <button>Request for Update</button>
              <button>Print</button>
              <a
                href={businessData.director_id}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button>Download</button>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <h3>Utility Bill</h3>
            <div className='icons'>
              <AiOutlineRotateLeft onClick={() => setUtilityRotate('left')} />
              <MdOutline360 onClick={() => setUtilityRotate('upside')} />
              <AiOutlineRotateRight onClick={() => setUtilityRotate('right')} />
            </div>
            <img
              className={UtilityRotate}
              src={businessData.utility_bill}
              alt=''
            />
            <div className='buttons'>
              <button>Request for Update</button>
              <button>Print</button>
              <a
                href={businessData.utility_bill}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button>Download</button>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <h3>CAC Certificate</h3>

            {businessData.cac_cert === null ? (
              'Not Submitted'
            ) : (
              <>
                <div className='icons'>
                  <AiOutlineRotateLeft onClick={() => setCacRotate('left')} />
                  <MdOutline360 onClick={() => setCacRotate('upside')} />
                  <AiOutlineRotateRight onClick={() => setCacRotate('right')} />
                </div>
                <img className={CacRotate} src={businessData.cac_cert} alt='' />
                <div className='buttons'>
                  <button>Request for Update</button>
                  <button>Print</button>
                  <a
                    href={businessData.cac_cert}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button>Download</button>
                  </a>
                </div>
              </>
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <h3>Board Resolution Letter</h3>
            {businessData.board_res_letter === null ? (
              'Not Submitted'
            ) : (
              <>
                <div className='icons'>
                  <AiOutlineRotateLeft onClick={() => setBoardRotate('left')} />
                  <MdOutline360 onClick={() => setBoardRotate('upside')} />
                  <AiOutlineRotateRight
                    onClick={() => setBoardRotate('right')}
                  />
                </div>
                <img
                  className={BoardRotate}
                  src={businessData.board_res_letter}
                  alt=''
                />
                <div className='buttons'>
                  <button>Request for Update</button>
                  <button>Print</button>
                  <a
                    href={businessData.board_res_letter}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button>Download</button>
                  </a>
                </div>
              </>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <div className='buttons'>
        <button>Request for Update</button>
        <button>Print</button>
        <button>Download</button>
      </div> */}
    </div>
  )
}

export default SwiperProfile
