import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Image from '../../../../Images/Ellipse 35.png'
import Images from '../../../../Images/CAC Certificate.png'
import { MdOpenInNew } from 'react-icons/md'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import './Profile.scss'
import SwiperProfile from './SwiperProfile'
import Spinner from '../../../../Images/spinner.gif'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../Images/loder.gif.gif'

interface Props {
  listLoading: boolean
  notify: any
  businessUsers: {
    results: [
      {
        user: string
        business_name: string
        username: string
        create_date: string
        registered: boolean
        is_business: string
      }
    ]
  }
}
function SingleProfile({ businessUsers, listLoading, notify }: Props) {
  const navigate = useNavigate()
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [businessData, setBusinessData] = useState<{} | any>({})
  const [openImage, setOpenImage] = useState(false)
  const [headerDisplay, setHeaderDisplay] = useState('')
  const [declineModal, setDeclineModal] = useState(false)
  const [acceptModal, setAcceptModal] = useState(false)
  const [Reason, setReason] = useState('')
  const [formLoading, setFormLoading] = useState(false)

  const FetchUser = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_profile/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
      body: JSON.stringify({ user_id: id }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBusinessData(data)
          setLoading(false)
          if (data.rejected === true) {
            setHeaderDisplay('User’s Business Profile  was Declined.')
          } else if (data.approved === true || data.is_business === 'True') {
            setHeaderDisplay('User’s Business Profile has been Activated.')
          } else {
            setHeaderDisplay('Activation Request for User’s Business Profile')
          }
        })
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
  }
  useEffect(() => {
    FetchUser()
    // eslint-disable-next-line
  }, [])

  const onDecline = () => {
    setFormLoading(true)

    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_approve/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
      body: JSON.stringify({
        user_id: businessData.user,
        action: 'reject',
        reason: Reason,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            window.location.reload()
            setFormLoading(false)
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  const onAccept = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_approve/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${sessionStorage.getItem('userId')}`,
      },
      body: JSON.stringify({
        user_id: businessData.user,
        action: 'approve',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            window.location.reload()
            setFormLoading(false)
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }

  return loading ? (
    <div className='loader1'>
      <img src={Spinner} alt='' />
    </div>
  ) : (
    <>
      {openImage ? (
        <SwiperProfile
          setOpenImage={setOpenImage}
          businessData={businessData}
        />
      ) : (
        <div className='single-profile'>
          {acceptModal && (
            <div className='decline'>
              <div className='modal'>
                <div className='close'>
                  <FaTimes onClick={() => setAcceptModal(false)} />
                </div>
                <h1>Are you sure you want to accept request</h1>
                {formLoading ? (
                  <div className='buttons'>
                    <img src={Loader} />
                  </div>
                ) : (
                  <div className='buttons'>
                    <button onClick={() => setAcceptModal(false)}>
                      Cancel
                    </button>
                    <button onClick={onAccept}>Approve</button>
                  </div>
                )}
              </div>
            </div>
          )}
          {declineModal && (
            <div className='decline'>
              <div className='modal'>
                <div className='close'>
                  <FaTimes onClick={() => setDeclineModal(false)} />
                </div>
                <h1>Provide Reason for declined request</h1>
                <div className='inputty'>
                  <div className='count'>
                    <p>0/128</p>
                  </div>
                  <div className='texta'>
                    <div className='text_top'></div>
                    <textarea
                      name=''
                      id=''
                      cols={30}
                      rows={10}
                      placeholder='Reason Text'
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                  </div>
                  {formLoading ? (
                    <div className='buttons'>
                      <img src={Loader} />
                    </div>
                  ) : (
                    <button className='button' onClick={onDecline}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='top'>
            <div className='left'>
              <h2>{headerDisplay}</h2>
              {/* <p>
                Activating user’s request, provide them with access to virtual
                card benefits.
              </p> */}
            </div>
            {headerDisplay ===
              'Activation Request for User’s Business Profile' && (
              <div className='right'>
                <button onClick={() => setDeclineModal(true)}>Deny</button>
                <button onClick={() => setAcceptModal(true)}>Approve</button>
              </div>
            )}
          </div>
          <div className='back'>
            <h2 onClick={() => navigate('/dashboard/profiles')}>{'<'} Back</h2>
          </div>
          <div className='single_profile_body'>
            <div className='left'>
              <div className='head'>
                <div className='head_left'>
                  <h2>User’s Business Profile</h2>
                </div>
                <div className='head_right'>
                  <p>Click here to freeze user’s account</p>
                </div>
              </div>
              <div className='user_id'>
                <div className='img'>
                  <img src={Image} alt='' />
                </div>
                <div className='dk'>
                  <div className='card'>
                    <h2>{businessData.user}</h2>
                    <p>User ID</p>
                  </div>
                  <div className='card'>
                    <h2>{businessData.username}</h2>
                    <p>Username</p>
                  </div>
                </div>
              </div>
              <div className='form_like'>
                <section>
                  <div className='card'>
                    <h2>BUSINESS NAME</h2>
                    <div className='input'>
                      <p>{businessData.business_name}</p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>DATE CREATED</h2>
                    <div className='input'>
                      <p>{new Date(businessData.create_date).toDateString()}</p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>CAC NUMBER</h2>
                    <div className='input'>
                      <p>
                        {businessData.cac_no === null
                          ? 'Not submitted'
                          : businessData.cac_no}
                      </p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>TIN NUMBER</h2>
                    <div className='input'>
                      <p>
                        {businessData.tin_no === null
                          ? 'Not submitted'
                          : businessData.tin_no}
                      </p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>Business Type</h2>
                    <div className='input'>
                      <p>
                        {businessData.registered === false
                          ? 'Not a registered business'
                          : 'Registered business'}
                      </p>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='card'>
                    <h2>DIRECTOR NAME</h2>
                    <div className='input'>
                      <p>{businessData.director_name}</p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>DIRECTOR ID NUMBER</h2>
                    <div className='input'>
                      <p>{businessData.director_id_no}</p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>DIRECTOR ID TYPE</h2>
                    <div className='input'>
                      <p>{businessData.director_id_type}</p>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='card'>
                    <h2>UTILITY BILL TYPE</h2>
                    <div className='input'>
                      <p>{businessData.utility_bill_type}</p>
                    </div>
                  </div>
                  <div className='card'>
                    <h2>DATE UPDATED</h2>
                    <div className='input'>
                      <p>{new Date(businessData.update_date).toDateString()}</p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='right'>
              <div className='head'>
                <div className='head_top'>
                  <h2>Document Preview</h2>
                  <MdOpenInNew onClick={() => setOpenImage(true)} />
                </div>
                <div className='head_swipe'>
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className='mySwiper'
                  >
                    <SwiperSlide>
                      <div>
                        <img src={businessData.director_id} alt='' />
                        <h3>Director ID</h3>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <img src={businessData.utility_bill} alt='' />
                        <h3>Utility Bill</h3>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        {businessData.cac_cert === null ? (
                          'Not Submitted'
                        ) : (
                          <img src={businessData.cac_cert} alt='' />
                        )}

                        <h3>CAC Certificate</h3>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        {businessData.board_res_letter === null ? (
                          'Not Submitted'
                        ) : (
                          <img src={businessData.board_res_letter} alt='' />
                        )}

                        <h3>Board Resolution Letter</h3>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className='head'>
                <div className='head_top'>
                  <h2 className='nh'>More Users</h2>
                </div>
                {!listLoading && (
                  <div className='head_wrap'>
                    {businessUsers.results
                      .sort((a, b) => 0.5 - Math.random())
                      .slice(0, 6)
                      .map((item) => (
                        <div
                          className={
                            item.user === businessData.user
                              ? 'card active'
                              : 'card'
                          }
                          onClick={() => {
                            window.location.replace(
                              `/dashboard/profile/${item.user}`
                            )
                          }}
                          key={item.user}
                        >
                          <div className='flex'>
                            <h2>{item.business_name}</h2>
                            <p>{item.user}</p>
                          </div>
                          <p>{item.username}</p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SingleProfile
