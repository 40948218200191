import React, { useEffect, useState } from 'react'
import './ForeignBene.scss'
import Spinner from '../../../../Images/spinner.gif'
import Loader from '../../../../Images/loder.gif.gif'

function ForeignBene({ notify, logOut }: any) {
  const [loading, setLoading] = useState(true)
  const [requestList, setrequestList] = useState<any>({})
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [requestLoading, setRequestLoading] = useState(false)
  useEffect(() => {
    fetch(
      `https://9umubpcrfa.execute-api.eu-west-1.amazonaws.com/prod/admin/pending_beneficiaries`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data)
          setrequestList(data.data)
          setLoading(false)
        })
      } else if (res.status === 403 || res.status === 429) {
        logOut()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  const replyTo = (action: string) => {
    setRequestLoading(true)
    fetch(
      `https://9umubpcrfa.execute-api.eu-west-1.amazonaws.com/prod/admin/approve_beneficiary`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${sessionStorage.getItem('userId')}`,
        },
        body: JSON.stringify({
          beneficiary_id: selectedUser.beneficiary_id,
          action: action,
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data)

          notify('success', data.message)
          setRequestLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('success', data.message)

          setRequestLoading(false)
        })
      }
    })
  }
  return loading ? (
    <div className='loader1'>
      <img src={Spinner} alt='' />
    </div>
  ) : (
    <div className='foreign'>
      <div className='left'>
        <div className='top'>
          <div className='tleft'>
            <h2>Foreign Beneficiary Request</h2>
            <p>All foreign request will all appear here.</p>
          </div>
          <div className='tright'>
            {/* <select name='' id=''>
              <option value=''>Aproved </option>
            </select> */}
            <input type='search' name='' id='' placeholder='Search...' />
          </div>
        </div>
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th>SW Username</th>
                <th>COMPANY</th>
                <th>BANK NAME</th>
                <th>CURRENCY</th>
                <th>COUNTRY</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {requestList
                .sort(
                  (a: any, b: any) =>
                    +new Date(b.created) - +new Date(a.created)
                )
                .map((item: any) => (
                  <tr
                    key={item.beneficiary_id}
                    onClick={() => setSelectedUser(item)}
                    className={
                      selectedUser &&
                      selectedUser.beneficiary_id === item.beneficiary_id
                        ? 'active'
                        : ''
                    }
                  >
                    <td>{item.user_username}</td>
                    <td>{item.company_name}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.currency}</td>
                    <td>{item.country}</td>
                    <td>
                      <button className={item.verification_status}>
                        {item.verification_status}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='right'>
        {selectedUser ? (
          <>
            <div className='top'>
              <p>Are you approving, this beneficiary?</p>
              <div className='buttons'>
                {requestLoading ? (
                  <div className='inm'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <>
                    {selectedUser.verification_status === 'pending' ? (
                      <button
                        className='accept'
                        onClick={() => replyTo('processing')}
                      >
                        PROCESS
                      </button>
                    ) : selectedUser.verification_status === 'approved' ||
                      selectedUser.verification_status === 'verified' ? (
                      <button className='accept'>APPROVED</button>
                    ) : selectedUser.verification_status === 'processing' ? (
                      <button
                        className='accept'
                        onClick={() => replyTo('approved')}
                      >
                        COMPLETE
                      </button>
                    ) : (
                      <button className='declinex'>DECLINED</button>
                    )}
                    {(selectedUser.verification_status === 'pending' ||
                      selectedUser.verification_status === 'processing') && (
                      <button
                        className='declinex'
                        onClick={() => replyTo('failed')}
                      >
                        DECLINE
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='restX'>
              <div className='rest-head'>
                <div className='flec'>
                  <p>{selectedUser.ac_no ? 'Account Number' : 'IBAN'}</p>
                  <button>{selectedUser.verification_status}</button>
                </div>
                <div className='ac'>
                  <h1>
                    {' '}
                    {selectedUser.ac_no
                      ? selectedUser.ac_no
                      : selectedUser.iban}
                  </h1>
                </div>
                <div className='rou'>
                  <p>
                    {' '}
                    {selectedUser.sort_code !== null
                      ? 'Sort Code'
                      : selectedUser.routing_code !== null
                      ? 'Routing Code'
                      : selectedUser.cnaps !== null
                      ? 'CNAPS'
                      : selectedUser.routing_no_aba !== null
                      ? 'Routing no / Aba code'
                      : selectedUser.swift !== null
                      ? 'Swift'
                      : ''}
                  </p>
                  <h2>
                    {' '}
                    {selectedUser.sort_code !== null
                      ? selectedUser.sort_code
                      : selectedUser.routing_code !== null
                      ? selectedUser.routing_code
                      : selectedUser.cnaps !== null
                      ? selectedUser.cnaps
                      : selectedUser.routing_no_aba !== null
                      ? selectedUser.routing_no_aba
                      : selectedUser.swift !== null
                      ? selectedUser.swift
                      : ''}
                  </h2>
                </div>
                <div className='buttons'>
                  <button>view Invoice</button>
                  <button>Download Invoice</button>
                </div>
              </div>
              <div className='rest-body'>
                <div className='card'>
                  <p>Date</p>
                  <h2>
                    <span>*</span>
                    {new Date(selectedUser.created).toDateString()}
                  </h2>
                </div>
                <div className='card'>
                  <p>Time</p>
                  <h2>
                    <span>*</span>
                    {new Date(selectedUser.created).toLocaleTimeString()}
                  </h2>
                </div>
                <div className='card'>
                  <p>Type</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.entity}
                  </h2>
                </div>
                <div className='card'>
                  <p>Currency</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.currency}
                  </h2>
                </div>
                <div className='card'>
                  <p>Bank</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.bank_name}
                  </h2>
                </div>
                <div className='card'>
                  <p>Email</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.recipient_email}
                  </h2>
                </div>
                <div className='card'>
                  <p>Address</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary_address}
                  </h2>
                </div>
                <div className='card'>
                  <p>City</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.beneficiary_city}
                  </h2>
                </div>
                <div className='card'>
                  <p>Country</p>
                  <h2>
                    <span>*</span>
                    {selectedUser.country}
                  </h2>
                </div>
                {selectedUser.intermediary && (
                  <>
                    <div className='card'>
                      <p>Intermediary bank name</p>
                      <h2>{selectedUser.intermediary_bank_name}</h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary bank address</p>
                      <h2>{selectedUser.intermediary_bank_address}</h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary ac country</p>
                      <h2>{selectedUser.intermediary_ac_country}</h2>
                    </div>
                    <div className='card'>
                      <p>Intermediary label tag</p>
                      <h2>{selectedUser.intermediary_label_tag}</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='empty'>
              <p>
                Selected Foreign beneficiary Approval details will be displayed
                here.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ForeignBene
